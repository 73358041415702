import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const companiesApiSlice = createApi({
  reducerPath: 'api', // Optional name for the slice
  baseQuery: fetchBaseQuery({ baseUrl: 'https://codebondingworkforce.com/api/' }),
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => 'companies',
    }),
  }),
});

// Export the hook generated by RTK Query
export const { useGetCompaniesQuery } = companiesApiSlice;
